import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import Artwork from '../../../components/artwork/artwork';
import Media from '../../../components/media/media';
import Storyline from '../../../components/storyline/storyline';
import IconLink from '../../../components/IconLink';
import BackToTop from '../../../components/back-to-top/BackToTop';
import './artwork.css';

// fluid(maxWidth: 2000, quality: 90) {

function Works() {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" }, sourceInstanceName: { eq: "riot" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
                base64
                sizes
                src
                srcSet
                originalName
              }
            }
          }
        }
      }
    }
  `);
  const images = data.allFile.edges.reduce(
    (obj, item) =>
      Object.assign(obj, { [item.node.childImageSharp.fluid.originalName]: item.node }),
    {},
  );
  return (
    <Layout>
      <SEO title="RIOT" />
      <div className="ai-workspage">
        <div className="ai-workpage-main-section">
          <h1>RIOT - Dogma Resistance LP</h1>
          <div className="ai-workpage-links">
            <IconLink text="Storyline" href="#storyline" />
            <IconLink text="Artworks" href="#artworks" />
            <IconLink text="CD & Vinyl" href="#cd" />
          </div>
        </div>
        <div className="ai-gap--lg">
          <div className="ai-workpage-section ai-gap--lg" id="artworks">
            <h2 className="ai-workpage-section__header">
              <span className="ai-workpage-section__header-container">Artworks</span>
            </h2>
            <div className="ai-gap--xl">
              <Artwork
                color="intenseblue"
                title="Jungle Fury"
                texts={[
                  `“Around: 2000 BC, the Maya civilization took place and started to spread around Yucatán,
            Mexico. After thousands of years of hard work trying to build their places to live and trying to
            survive against other civilizations and tribes around, their prays were heard. A mysterious
            traveler came to visit the emperor, and let him “the X”.`,
                  `This scepter had the power to build any kind of robot ever imagined. With it, Mayas started to
            create mechanical guardians to build their desired place and to survive for several years.”`,
                ]}
                coverImage={images['junglefurycover.jpg'].childImageSharp.fluid}
                videoUrl="https://www.youtube.com/embed/HYYx92EZOnI"
                instaImages={[
                  images['jungleinsta1.jpg'].childImageSharp.fluid,
                  images['jungleinsta2.jpg'].childImageSharp.fluid,
                ]}
                videos={[
                  'https://alexillustration.s3.eu-west-2.amazonaws.com/jungleinstavideo.mp4',
                  'https://alexillustration.s3.eu-west-2.amazonaws.com/junglevideosquare.mp4',
                ]}
                logo={
                  <svg
                    preserveAspectRatio="xMidYMid"
                    width="100"
                    height="149"
                    viewBox="0 0 762 1136"
                  >
                    <g>
                      <path
                        d="M104.000,382.000 L223.000,742.000 L185.000,832.000 L49.000,495.000 L104.000,382.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M658.000,382.000 L539.000,742.000 L577.000,832.000 L713.000,495.000 L658.000,382.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M227.000,184.000 L313.000,636.000 L364.000,530.000 L364.000,-0.000 L227.000,184.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M535.000,184.000 L449.000,636.000 L398.000,530.000 L398.000,-0.000 L535.000,184.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M381.000,603.000 L560.000,939.000 L381.000,1136.000 L381.000,1076.000 L445.000,998.000 L381.000,838.000 L381.000,603.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M381.000,603.000 L202.000,939.000 L381.000,1136.000 L381.000,1076.000 L317.000,998.000 L381.000,838.000 L381.000,603.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M346.000,992.000 L381.000,1046.000 L419.000,992.000 L381.000,882.000 L346.000,992.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M451.156,1121.811 L522.027,1045.220 L515.823,1091.377 L472.874,1126.096 L451.156,1121.811 Z"
                        className="cls-1"
                      />
                      <path
                        d="M310.846,1121.711 L240.004,1045.001 L246.206,1091.230 L289.137,1126.002 L310.846,1121.711 Z"
                        className="cls-1"
                      />
                      <path
                        d="M628.000,920.000 L679.000,840.000 L665.000,766.000 L623.000,846.000 L628.000,920.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M721.000,780.000 L762.000,703.000 L747.000,594.000 L705.000,696.000 L721.000,780.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M134.000,920.000 L83.000,840.000 L97.000,766.000 L139.000,846.000 L134.000,920.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M41.000,780.000 L-0.000,703.000 L15.000,594.000 L57.000,696.000 L41.000,780.000 Z"
                        className="cls-1"
                      />
                    </g>
                  </svg>
                }
              />
              <Artwork
                color="pink"
                title="Disorder"
                texts={[
                  `“... but going back in time, a man suddenly felt in a wormhole, and found himself in an ancient mechanical temple in the middle of nowhere in space.`,
                  `After some time exploring the place, he got to the final spot of the ruins. In the middle of it, there was a scepter. And as soon as he grabbed it, everything around started shaking. ‘The Creator’, a multiple long arms robot appeared to protect it, but as soon as he attacked, the man instantly went back to Earth by another wormhole.”`,
                ]}
                coverImage={images['disordercover.jpg'].childImageSharp.fluid}
                videoUrl="https://www.youtube.com/embed/h77mOcrb0k0"
                instaImages={[
                  images['disorderinsta1.jpg'].childImageSharp.fluid,
                  images['disorderinsta2.jpg'].childImageSharp.fluid,
                ]}
                videos={[
                  'https://alexillustration.s3.eu-west-2.amazonaws.com/disorderinstavideo.mp4',
                  'https://alexillustration.s3.eu-west-2.amazonaws.com/disordervideosquare.mp4',
                ]}
                logo={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid"
                    width="100"
                    height="134"
                    viewBox="0 0 818 1094"
                  >
                    <g>
                      <path
                        d="M409.000,1094.000 L582.000,971.000 L644.000,861.000 L512.000,943.000 L532.000,823.000 L774.000,596.000 L765.000,651.000 L818.000,563.000 L761.000,250.000 L548.000,390.000 L515.000,275.000 L648.000,114.000 L617.000,101.000 L464.000,249.000 L425.000,144.000 L490.000,37.000 L409.000,-0.000 L409.000,1094.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M553.000,850.000 L537.000,904.000 L700.000,805.000 L752.000,666.000 L553.000,850.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M409.000,1094.000 L236.000,971.000 L174.000,861.000 L306.000,943.000 L286.000,823.000 L44.000,596.000 L53.000,651.000 L-0.000,563.000 L57.000,250.000 L270.000,390.000 L303.000,275.000 L170.000,114.000 L201.000,101.000 L354.000,249.000 L393.000,144.000 L328.000,37.000 L409.000,-0.000 L409.000,1094.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M265.000,850.000 L281.000,904.000 L118.000,805.000 L66.000,666.000 L265.000,850.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M511.000,49.000 L449.000,145.000 L471.000,208.000 L591.000,90.000 L511.000,49.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M674.000,125.000 L541.000,280.000 L561.000,349.000 L753.000,224.000 L739.000,154.000 L674.000,125.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M307.000,49.000 L369.000,145.000 L347.000,208.000 L227.000,90.000 L307.000,49.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M144.000,125.000 L277.000,280.000 L257.000,349.000 L65.000,224.000 L79.000,154.000 L144.000,125.000 Z"
                        className="cls-1"
                      />
                    </g>
                  </svg>
                }
              />
              <Artwork
                color="yellow"
                title="Aiwa"
                texts={[
                  `“As the time goes by, the X changed hands until it fell into the Egyptian civilization.`,
                  `They used the X to build their pyramids and, as Mayas did, they paid honor to them by writing all the story on their structures as hieroglyphics and wall inscriptions. They end up calling them ‘gods’.”`,
                ]}
                coverImage={images['aiwacover.jpg'].childImageSharp.fluid}
                videoUrl="https://www.youtube.com/embed/lmTLeQFnJUw"
                instaImages={[
                  images['aiwainsta2.jpg'].childImageSharp.fluid,
                  images['aiwainsta1.jpg'].childImageSharp.fluid,
                ]}
                videos={[
                  'https://alexillustration.s3.eu-west-2.amazonaws.com/aiwainstavideo.mp4',
                  'https://alexillustration.s3.eu-west-2.amazonaws.com/aiwavideosquare.mp4',
                ]}
                logo={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid"
                    width="110"
                    height="104"
                    viewBox="0 0 1086 1021"
                  >
                    <g>
                      <path
                        d="M867.000,104.000 L889.000,39.000 L1086.000,-0.000 L1054.000,70.000 L1058.000,98.000 L921.000,115.000 L913.000,152.000 L867.000,104.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M683.000,141.031 L543.000,240.000 L543.000,352.000 L680.000,252.000 L727.000,141.031 L683.000,141.031 Z"
                        className="cls-1"
                      />
                      <path
                        d="M786.000,141.031 L721.000,274.000 L578.000,380.000 L665.000,443.000 L775.000,584.000 L754.000,516.000 L857.000,179.000 L786.000,141.031 Z"
                        className="cls-1"
                      />
                      <path
                        d="M543.000,141.000 L707.000,67.000 L543.000,67.000 L543.000,141.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M543.000,1021.000 L619.000,957.000 L666.000,838.000 L543.000,764.000 L543.000,1021.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M543.000,727.000 L683.000,796.000 L758.000,650.000 L655.000,546.000 L543.000,528.000 L543.000,727.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M938.000,158.031 L840.000,545.000 L847.000,689.000 L915.000,484.000 L1031.000,366.000 L1035.000,138.000 L938.000,158.031 Z"
                        className="cls-1"
                      />
                      <path
                        d="M666.000,997.000 L737.000,815.000 L768.000,859.000 L731.000,939.000 L666.000,997.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M758.000,790.000 L791.000,840.000 L815.000,730.000 L794.000,703.000 L758.000,790.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M219.000,104.000 L197.000,39.000 L-0.000,-0.000 L32.000,70.000 L28.000,98.000 L165.000,115.000 L173.000,152.000 L219.000,104.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M403.000,141.031 L543.000,240.000 L543.000,352.000 L406.000,252.000 L359.000,141.031 L403.000,141.031 Z"
                        className="cls-1"
                      />
                      <path
                        d="M300.000,138.031 L365.000,271.000 L508.000,377.000 L421.000,440.000 L311.000,581.000 L332.000,513.000 L229.000,176.000 L300.000,138.031 Z"
                        className="cls-1"
                      />
                      <path
                        d="M543.000,496.248 L458.000,512.000 L477.676,474.194 L543.000,423.000 L608.324,474.194 L628.000,512.000 L543.000,496.248 Z"
                        className="cls-1"
                      />
                      <path
                        d="M543.000,141.000 L379.000,67.000 L543.000,67.000 L543.000,141.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M543.000,1021.000 L467.000,957.000 L420.000,838.000 L543.000,764.000 L543.000,1021.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M543.000,727.000 L403.000,796.000 L328.000,650.000 L431.000,546.000 L543.000,528.000 L543.000,727.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M148.000,158.031 L246.000,545.000 L239.000,689.000 L171.000,484.000 L55.000,366.000 L51.000,138.000 L148.000,158.031 Z"
                        className="cls-1"
                      />
                      <path
                        d="M420.000,997.000 L349.000,815.000 L318.000,859.000 L355.000,939.000 L420.000,997.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M328.000,790.000 L295.000,840.000 L271.000,730.000 L292.000,703.000 L328.000,790.000 Z"
                        className="cls-1"
                      />
                    </g>
                  </svg>
                }
              />
              <Artwork
                color="blue"
                title="The Mob"
                texts={[
                  `“LA, 2019. Tom & Daniel found themselves, in the middle of the city, when suddenly, the whole sky turned red, and an army of mechanical worms came down from a spaceship to destroy Earth and to return what it was stolen.`,
                  `Humans tried to fight them, but there were too many and too advanced for them. On their last breath, Tom & Daniel shouted out loud, and their “X” amulet started floating and shining.”`,
                ]}
                coverImage={images['themobcover.jpg'].childImageSharp.fluid}
                videoUrl="https://www.youtube.com/embed/RKiNuJ9tbs8"
                instaImages={[
                  images['themobinsta1.jpg'].childImageSharp.fluid,
                  images['themobinsta2.jpg'].childImageSharp.fluid,
                ]}
                videos={[
                  'https://alexillustration.s3.eu-west-2.amazonaws.com/themobinstavideo.mp4',
                  'https://alexillustration.s3.eu-west-2.amazonaws.com/themobvideosquare.mp4',
                ]}
                logo={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid"
                    width="140"
                    height="120"
                    viewBox="0 0 1391 1108"
                  >
                    <g>
                      <path
                        d="M875.000,210.594 L778.000,452.703 L737.000,481.000 L696.000,418.000 L696.000,210.001 L875.000,210.594 Z"
                        className="cls-1"
                      />
                      <path
                        d="M1214.000,210.594 L885.000,780.852 L803.000,605.773 L769.000,579.000 L786.000,554.000 L770.000,529.000 L817.000,488.720 L957.000,210.594 L1214.000,210.594 Z"
                        className="cls-1"
                      />
                      <path
                        d="M737.000,627.000 L696.000,689.000 L696.000,1108.000 L837.000,862.889 L751.000,639.788 L737.000,627.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M517.000,210.594 L614.000,452.703 L655.000,481.000 L696.000,418.000 L696.000,210.001 L517.000,210.594 Z"
                        className="cls-1"
                      />
                      <path
                        d="M178.000,210.594 L507.000,780.852 L589.000,605.773 L623.000,579.000 L606.000,554.000 L622.000,529.000 L575.000,488.720 L435.000,210.594 L178.000,210.594 Z"
                        className="cls-1"
                      />
                      <path
                        d="M655.000,627.000 L696.000,689.000 L696.000,1108.000 L555.000,862.889 L641.000,639.788 L655.000,627.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M695.500,151.000 L383.000,151.000 L464.018,53.000 L695.500,53.000 L926.981,53.000 L1008.000,151.000 L695.500,151.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M1125.120,683.695 L1009.380,884.164 L884.000,905.328 L1040.250,634.695 L1196.500,364.062 L1240.861,483.226 L1125.120,683.695 Z"
                        className="cls-1"
                      />
                      <path
                        d="M381.576,883.831 L265.795,683.467 L150.014,483.102 L194.390,364.000 L350.695,634.492 L507.000,904.985 L381.576,883.831 Z"
                        className="cls-1"
                      />
                      <path
                        d="M1085.000,151.000 L1215.000,-0.000 L1385.000,-0.000 L1304.000,73.000 L1270.000,151.000 L1085.000,151.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M1332.000,88.000 L1312.000,133.000 L1391.000,88.000 L1332.000,88.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M306.000,151.000 L176.000,-0.000 L6.000,-0.000 L87.000,73.000 L121.000,151.000 L306.000,151.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M59.000,88.000 L79.000,133.000 L-0.000,88.000 L59.000,88.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M696.000,635.000 L642.000,553.500 L696.000,472.000 L750.000,553.500 L696.000,635.000 Z"
                        className="cls-1"
                      />
                    </g>
                  </svg>
                }
              />
              <Artwork
                color="green"
                title="Overkill"
                texts={[
                  `“The amulet used all its power to create Overkill, the most advanced robot ever created. A giant mecha to save human race.`,
                  `As the Overkill machine felt all the hopes from humans and how the scepter allowed them to survive for several years, he powered up all his weapons to the limit, to end up beating The Creator’s army.`,
                  `Now that the machine used all his power to save humanity, he came back to his amulet form, making it unable to use, but, who knows if after his thousands of years of rest needed he will come back to life for another RIOT.”`,
                ]}
                coverImage={images['overkillcover.jpg'].childImageSharp.fluid}
                videoUrl="https://www.youtube.com/embed/RKFE4Anpack"
                instaImages={[
                  images['overkillinsta1.jpg'].childImageSharp.fluid,
                  images['overkillinsta2.jpg'].childImageSharp.fluid,
                ]}
                videos={[
                  'https://alexillustration.s3.eu-west-2.amazonaws.com/overkillinstavideo.mp4',
                  'https://alexillustration.s3.eu-west-2.amazonaws.com/overkillvideosquare.mp4',
                ]}
                logo={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid"
                    width="110"
                    height="123"
                    viewBox="0 0 1026 1164"
                  >
                    <g>
                      <path
                        d="M742.000,762.000 L707.000,662.000 L669.000,705.000 L668.000,599.000 L611.000,419.000 L607.000,327.000 L657.000,273.000 L632.000,114.000 L774.000,256.000 L846.000,634.000 L742.000,762.000 ZM710.000,636.000 L730.000,549.000 L715.000,507.000 L692.000,587.000 L710.000,636.000 ZM670.000,501.000 L680.000,546.000 L706.000,482.000 L692.000,449.000 L670.000,501.000 ZM741.318,420.035 L692.768,393.713 L756.000,556.000 L759.299,511.368 L808.116,534.539 L741.318,420.035 Z"
                        className="cls-1"
                      />
                      <path
                        d="M895.000,895.000 L934.000,1001.000 L981.000,908.000 L1010.000,889.000 L1007.000,787.000 L981.000,749.000 L961.000,782.000 L932.000,803.000 L924.000,853.000 L895.000,895.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M888.000,873.000 L909.000,842.000 L913.000,786.000 L955.000,754.000 L970.000,724.000 L931.000,660.000 L853.000,693.000 L787.000,896.000 L829.000,922.000 L888.000,873.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M986.000,938.000 L962.000,992.000 L1026.000,1125.000 L1017.000,929.000 L986.000,938.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M856.000,485.000 L885.000,634.000 L932.000,602.000 L984.000,653.000 L952.000,559.000 L856.000,485.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M513.000,1014.000 L513.000,1164.000 L664.000,1032.000 L768.000,831.000 L759.000,792.000 L626.000,979.000 L624.000,902.000 L547.000,997.000 L513.000,1014.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M588.000,367.000 L560.000,433.000 L513.000,342.000 L513.000,-0.000 L616.000,102.000 L636.000,235.000 L586.000,287.000 L588.000,367.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M419.000,327.000 L415.000,419.000 L358.000,599.000 L357.000,705.000 L319.000,662.000 L284.000,762.000 L180.000,634.000 L252.000,256.000 L394.000,114.000 L369.000,273.000 L419.000,327.000 ZM316.000,636.000 L334.000,587.000 L311.000,507.000 L296.000,549.000 L316.000,636.000 ZM284.682,420.035 L217.884,534.539 L266.701,511.368 L270.000,556.000 L333.232,393.713 L284.682,420.035 ZM334.000,449.000 L320.000,482.000 L346.000,546.000 L356.000,501.000 L334.000,449.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M131.000,895.000 L92.000,1001.000 L45.000,908.000 L16.000,889.000 L19.000,787.000 L45.000,749.000 L65.000,782.000 L94.000,803.000 L102.000,853.000 L131.000,895.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M138.000,873.000 L117.000,842.000 L113.000,786.000 L71.000,754.000 L56.000,724.000 L95.000,660.000 L173.000,693.000 L239.000,896.000 L197.000,922.000 L138.000,873.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M40.000,938.000 L64.000,992.000 L-0.000,1125.000 L9.000,929.000 L40.000,938.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M170.000,485.000 L141.000,634.000 L94.000,602.000 L42.000,653.000 L74.000,559.000 L170.000,485.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M513.000,1014.000 L513.000,1164.000 L362.000,1032.000 L258.000,831.000 L267.000,792.000 L400.000,979.000 L402.000,902.000 L479.000,997.000 L513.000,1014.000 Z"
                        className="cls-1"
                      />
                      <path
                        d="M438.000,367.000 L466.000,433.000 L513.000,342.000 L513.000,-0.000 L410.000,102.000 L390.000,235.000 L440.000,287.000 L438.000,367.000 Z"
                        className="cls-1"
                      />
                    </g>
                  </svg>
                }
              />
              <Artwork
                color="white"
                title="Artwork [LP]"
                texts={[
                  `Even though I wanted the project to be as personal as possible, I felt super inspired by the original LP Artwork composition concept made by Amanda Cha.`,
                  `To honor her, I made an X composition with all my beasts.`,
                ]}
                coverImage={images['artworkcover.jpg'].childImageSharp.fluid}
                instaImages={[
                  images['artworkinsta1.jpg'].childImageSharp.fluid,
                  images['artworkinsta2.jpg'].childImageSharp.fluid,
                ]}
                flyer={images['artworkflyer.jpg'].childImageSharp.fluid}
                videos={[]}
                logo={
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 500 500"
                    width="170"
                    height="170"
                  >
                    <g id="Capa_2">
                      <polygon
                        id="XMLID_7_"
                        className="st2"
                        points="166,88.4 146.3,127.1 191.8,216.4 214.1,235.7 229.7,213.2 210.7,200.5 	"
                      />
                      <polygon
                        id="XMLID_6_"
                        className="st2"
                        points="332.4,88.4 352.1,127.1 306.6,216.4 284.3,235.7 268.8,213.2 287.7,200.5 	"
                      />
                      <polygon
                        id="XMLID_5_"
                        className="st2"
                        points="166,410.3 146.3,371.7 191.8,282.4 214.1,263 229.7,285.6 210.7,298.3 	"
                      />
                      <polygon
                        id="XMLID_4_"
                        className="st2"
                        points="332.4,410.3 352.1,371.7 306.6,282.4 284.3,263 268.8,285.6 287.7,298.3 	"
                      />
                      <polygon
                        id="XMLID_3_"
                        className="st2"
                        points="249.1,210.5 274.2,247.6 249.1,284.8 224.4,247.6 	"
                      />
                      <polygon
                        id="XMLID_2_"
                        className="st2"
                        points="134.8,221.3 134.8,274 172.9,247.6 	"
                      />
                      <polygon
                        id="XMLID_1_"
                        className="st2"
                        points="365.3,221.3 365.3,274 327.2,247.6 	"
                      />
                    </g>
                  </svg>
                }
              />
            </div>
          </div>
          <div className="ai-workpage-section ai-gap--lg" id="storyline">
            <h2 className="ai-workpage-section__header">
              <span className="ai-workpage-section__header-container">Storyline</span>
            </h2>
            <Storyline image={images['storyline.jpg'].childImageSharp.fluid} />
          </div>
          <div className="ai-workpage-section ai-gap--lg" id="cd">
            <h2 className="ai-workpage-section__header">
              <span className="ai-workpage-section__header-container">CD & VINYL</span>
            </h2>
            <div className="ai-gap--xl">
              <Media
                title="CD"
                images={[
                  images['cd1.jpg'].childImageSharp.fluid,
                  images['cd2.jpg'].childImageSharp.fluid,
                  images['cd3.jpg'].childImageSharp.fluid,
                  images['cd4.jpg'].childImageSharp.fluid,
                ]}
              />
              <Media
                title="VINYL"
                images={[
                  images['vinyl1.jpg'].childImageSharp.fluid,
                  images['vinyl2.jpg'].childImageSharp.fluid,
                  images['vinyl3.jpg'].childImageSharp.fluid,
                  images['vinyl4.jpg'].childImageSharp.fluid,
                ]}
              />
            </div>
          </div>
        </div>
        <BackToTop />
      </div>
    </Layout>
  );
}

export default Works;
