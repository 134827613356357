import React from 'react';
import Img from '../image';
import Twolines from '../twolines/twolines';

import './storyline.css';

function Storyline({ image }) {
  return (
    <div className="ai-storyline">
      <div className="container">
        <div className="ai-storyline__container">
          <div className="ai-storyline__lines-container ai-storyline__lines-container--left">
            <Twolines />
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
              <Img fluid={image} className="ai-img-container" alt="logo image" />
            </div>
          </div>
          <div className="ai-storyline__lines-container ai-storyline__lines-container--right">
            <Twolines />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Storyline;
