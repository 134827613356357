import React from 'react';
import Img from '../image';
import Caption from '../caption/caption';
import AiVideo from '../video';
import YoutubeVideo from '../YoutubeVideo';

const ImageCaptions = {
  0: 'IG Stories - Mobile Wallpaper',
  1: 'IG Stories - Wallpaper next!',
};

const VideoCaptions = {
  0: 'IG Stories - Animation',
  1: 'IG & Twitter Post - Animation',
};

function Artwork({ color, title, logo, texts, coverImage, videoUrl, instaImages, flyer, videos }) {
  return (
    <div className={`ai-workpage-subsection ai-artwork ai-artwork--${color}`}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="ai-workpage-section__lines-container">
              <div className="ai-workpage-section__lines ai-workpage-section__lines__riot" />
            </div>
          </div>
        </div>
      </div>
      <div className="ai-workpage-section__content">
        <div className="container ai-gap--md">
          <div className="row">
            <div className="col-md-offset-1 col-md-2 col-xs-12">
              <h3 className="ai-artwork__title">{title}</h3>
            </div>
            <div className="col-md-4 col-md-offset-0 col-xs-12 col-sm-8 col-sm-offset-2 ai-margin-top--md-at-xs ai-margin-top--md-at-sm">
              <div className="ai-artwork__info">
                <div className="ai-artwork__logo">{logo}</div>
                <div className="ai-artwork__text">
                  {texts.map((para) => (
                    <p>{para}</p>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-5 col-md-offset-0 col-xs-12 col-sm-8 col-sm-offset-2 ai-margin-top--md-at-xs ai-margin-top--md-at-sm">
              <Caption caption="Artwork">
                <Img fluid={coverImage} className="ai-img-container" alt="logo image" />
              </Caption>
            </div>
          </div>
          {videoUrl && (
            <div className="row">
              <div className="col-md-6 col-md-offset-6 col-sm-8 col-sm-offset-2 col-xs-12">
                <Caption caption="Youtube - Video animation">
                  <div className="ai-workpage-section__video">
                    <YoutubeVideo url={videoUrl} title={title} />
                  </div>
                </Caption>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12 col-md-offset-0 col-xs-12 col-sm-8 col-sm-offset-2">
              <div className="ai-artwork__media">
                {instaImages.map((imageSource, index) => (
                  <Caption caption={ImageCaptions[index]}>
                    <div className="ai-artwork__media-element">
                      <Img fluid={imageSource} className="ai-img-container" alt="instagram image" />
                    </div>
                  </Caption>
                ))}
                {videos.map((videoSource, index) => (
                  <Caption caption={VideoCaptions[index]}>
                    <div className="ai-artwork__media-element">
                      <AiVideo disablePictureInPicture controlsList="nodownload" controls>
                        <source src={videoSource} type="video/mp4" />
                      </AiVideo>
                    </div>
                  </Caption>
                ))}
                {flyer && (
                  <Caption caption="Europe Tour - Flyer">
                    <div className="ai-artwork__media-element ai-artwork__media-element--full-height">
                      <Img fluid={flyer} className="ai-img-container" alt="flyer" />
                    </div>
                  </Caption>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Artwork;
