import React from 'react';
import Img from '../image';

function Media({ title, images }) {
  return (
    <div className="ai-workpage-subsection">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="ai-workpage-section__lines-container">
              <div className="ai-workpage-section__lines" />
            </div>
          </div>
        </div>
      </div>
      <div className="ai-workpage-section__content">
        <div className="container ai-gap--md">
          <div className="row">
            <div className="col-md-offset-1 col-md-2 col-xs-12 col-sm-12">
              <h3 className="ai-artwork__title ai-media-title ai-text--white">{title}</h3>
            </div>
            <div className="col-md-4 col-md-offset-1 col-xs-12 col-sm-6 ai-margin-top--sm-at-xs ai-margin-top--sm-at-sm">
              <Img fluid={images[0]} className="ai-img-container" alt="logo image" />
            </div>
            <div className="col-md-4 col-xs-12 col-sm-6 ai-margin-top--sm-at-xs ai-margin-top--sm-at-sm">
              <Img fluid={images[1]} className="ai-img-container" alt="logo image" />
            </div>
          </div>
          <div className="row ai-gap--md-at-xs">
            <div className="col-md-4 col-md-offset-3 col-xs-12 col-sm-6">
              <Img fluid={images[2]} className="ai-img-container" alt="logo image" />
            </div>
            <div className="col-md-4 col-xs-12 col-sm-6 ai-margin-top--md-at-xs">
              <Img fluid={images[3]} className="ai-img-container" alt="logo image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Media;
